<template>
    <header class="header-nav">
        <div class="header-nav__banner" :class="{'header-nav__banner--overlay-opened': isOverlayOpened}">
            <a :href="homepageUrl" class="header-nav__home-link d-none d-sm-block" aria-label="Revenir à l'accueil">
                <img src="@/static/images/logo-cavp.svg" alt="Logo CAVP" class="header-nav__logo" aria-hidden="true" />
            </a>
            <a :href="homepageUrl" class="header-nav__home-link d-sm-none" aria-label="Revenir à l'accueil">
                <img src="@/static/images/logo-cavp-mobile.svg" alt="Logo CAVP" class="header-nav__logo" aria-hidden="true" />
            </a>

            <div class="header-nav__account-btn ml-auto d-flex d-xl-none mr-2 ">
                <my-account-button :url="espacePersoUrl" />
            </div>

            <burger-button class="d-xl-none" v-model="isOverlayOpened" />

            <nav class="header-nav__menu-container" aria-label="Menu principal">
                <ol class="header-nav__menu header-nav__menu--level-1">
                    <li v-for="(item, key) in items"
                        :key="key"
                        class="header-nav__menu-item header-nav__menu-item--level-1"
                    >
                        <a :href="item.cta.url"
                           :target="item.cta.target"
                           v-text="item.cta.label"
                           class="header-nav__menu-item-link body-1"
                        />

                        <div v-if="item.submenu.length" class="header-nav__menu-dropdown">
                            <div class="container-fluid">
                                <ol class="header-nav--menu header-nav__menu--level-2 row">
                                    <li v-for="(item, key) in item.submenu"
                                        :key="key"
                                        class="header-nav__menu-item header-nav__menu-item--level-2 col-4"
                                    >
                                        <a :href="item.cta.url"
                                           :target="item.cta.target"
                                           class="dropdown-menu-link"
                                        >
                                            <span v-text="item.cta.label" class="dropdown-menu-link__title body-1" />
                                            <div v-html="item.text" class="dropdown-menu-link__text body-2" />
                                        </a>
                                    </li>
                                </ol>
                            </div>
                        </div>
                    </li>
                </ol>

                <button @click="toggleSearchBar"
                        class="header-nav__search-button"
                        :class="{ 'header-nav__search-button--selected': isSearhBarOpened }"
                        aria-label="Rechercher"
                        type="button"
                >
                    <img src="@/static/images/icons/search.svg" alt="" width="24" height="24"/>
                </button>


                <my-account-button :url="espacePersoUrl" />
            </nav>
        </div>

        <slide-down>
            <header-search-panel v-if="isSearhBarOpened"
                                 @close="toggleSearchBar"
                                 :search-url="searchUrl"
                                 class="header-nav__search-bar"
                                 v-keyup-listener:Escape="toggleSearchBar"
            />
        </slide-down>

        <fade>
            <header-overlay-mobile v-if="isOverlayOpened"
                                   :items="items"
                                   :espace-perso-url="espacePersoUrl"
                                   :search-url="searchUrl"
                                   @close="closeMobileMenu"
                                   v-keyup-listener:Escape="closeMobileMenu"
                                   :is-connected="isConnected"
            />
        </fade>
    </header>
</template>

<script>
import HeaderSearchPanel from '@/app/components/HeaderSearchPanel';
import HeaderOverlayMobile from '@/app/components/HeaderOverlayMobile';
import MyAccountButton from '@/app/components/MyAccountButton';
import BurgerButton from '@/app/components/BurgerButton';
import SlideDown from '@/app/transitions/SlideDown';
import Fade from '@/app/transitions/Fade';
import keyupListener from '@/app/directives/escape-listener.js'

export default {
    name: "HeaderNav",

    components: {
        MyAccountButton,
        HeaderSearchPanel,
        SlideDown,
        Fade,
        HeaderOverlayMobile,
        BurgerButton,
    },

    props: {
        homepageUrl: { type: String, default: '/' },
        items: { type: Array, required: true },
        espacePersoUrl: { type: String, required: true },
        searchUrl: { type: String, required: true },
        isConnected: {type: Boolean, default: false},
    },

    directives: {
        keyupListener,
    },

    data() {
        return {
            isOverlayOpened: false,
            isSearhBarOpened: false,
        };
    },

    methods: {
        onClick() {
            console.log('clicked');
        },

        toggleSearchBar() {
            this.isSearhBarOpened = !this.isSearhBarOpened;
        },

        closeMobileMenu() {
            this.isOverlayOpened = false;
        },
    },
}
</script>

<style lang="scss">
    @use 'sass:math';
    @import "~@/assets/styles/abstracts/_variables.scss";


    $breakpoint-lg: 1200px;
    $transition-duration: 200ms;

    .header-nav {
        position: relative;

        background: $white;
        margin: 0 auto;

        &__banner {
            position: relative;
            z-index: 10;

            display: flex;
            align-items: center;

            @media (min-width: 1200px) {
                align-items: stretch;
            }

            max-width: 90rem;
            margin: 0 auto;

            &--overlay-opened {
                position: fixed;
                top: 0;
                left: 0;
                right: 0;
            }
        }

        &__account-btn a.my-account-button {
            @media (max-width: 1200px) {
                font-size: 12px;
            }
        }

        &__home-link {
            padding: 0.75rem 1rem;

            @media (min-width: 1200px) {
                padding: 1.25rem 2rem;
            }
        }

        &__logo {
            height: 40px;

            @media (min-width: 1200px) {
                height: 52px;
            }
        }

        &__burger-button,
        &__search-button {
            display: flex;

            justify-content: center;
            align-items: center;
            align-self: stretch;

            margin: 0 0.75rem;
            padding: 0.75rem;
        }

        &__burger-button {
            @media (min-width: 1200px) {
                display: none;
            }
        }

        &__search-button {
            position: relative;

            color: $color-sea;
        }

        &__search-bar {
            position: absolute !important;
            left: 0;
            right: 0;
            z-index: 5;
        }

        &__menu-container {
            display: none;

            align-items: center;

            padding: 0 1.5rem 0 0;

            @media (min-width: 1200px) {
                display: flex;
            }
        }

        &__menu {
            &--level-1 {
                display: flex;
                align-items: stretch;

                height: 100%;

                margin: 0;

                &::after {
                    content: "";
                    display: block;

                    align-self: center;

                    height: 1.125rem;
                    margin: 0 -0.5rem 0 0.5rem;
                    border-right: 1px solid $gray-400;
                }
            }

            &--level-2 {
                padding: 2.5rem 6rem;
            }
        }

        &__menu-dropdown {
            position: absolute;
            top: 100%;
            left: 0;
            right: 0;

            display: none;

            border-top: 1px solid $gray-100;

            background: $white;
        }

        &__menu-item {
            display: flex;

            & > * {
                transition: color $transition-duration ease-out;
            }

            &--level-1 {
                align-items: stretch;

                &:hover {
                    .header-nav__menu-dropdown {
                        display: flex;
                    }
                }
            }

            &--level-1:hover,
            &--level-1:focus,
            &--level-2:hover a,
            &--level-2:focus a {
                text-decoration: none;

                & > * {
                    color: $color-sea;
                }
            }
        }

        &__menu-item-link {
            position: relative;

            display: flex;
            align-items: center;

            padding: 0.75rem 1rem;
            color: $gray-850;

            transition: $transition-duration ease-out;
        }
    }

    ol {
        list-style: none;
        padding: 0;
    }

    .dropdown-menu-link {
        display: flex;
        flex-direction: column;

        padding: 1.5rem;
        border: 1px solid transparent;

        transition: background-color $transition-duration ease-out;

        &:hover,
        &:focus-within {
            background: $gray-100;
            border-color: $gray-200;
            border-radius: 8px;
        }

        &__title {
            margin-bottom: 0.5rem;

            color: $gray-850;
        }

        &__text {
            color: $gray-550;
            font-size: 0.875rem;
            line-height: math.div(24, 14);
        }
    }

    // Indicateurs d'onglet sélectionné
    .header-nav__menu-item-link,
    .header-nav__search-button
    {
        &::after {
            content: '';

            position: absolute;
            bottom: 0;
            left: 0.25rem;
            right: 0.25rem;

            height: 2px;

            background-color: currentColor;

            transform: scaleX(0);
            transform-origin: bottom right;

            transition: transform $transition-duration ease-out;
        }

        &:hover,
        &:focus,
        &--selected {
            text-decoration: none;

            &::after {
                transform: scaleX(1);
                transform-origin: bottom left;
            }
        }
    }
</style>
