<template>
    <div class="PostalCodeCompletion form-row">
        <div class="form-group col-md-4">
            <label for="postal-code">Code postal</label>
            <input
                type="text"
                class="form-control"
                :class="{'is-invalid': cpErrors.length > 0 }"
                id="postal-code"
                maxlength="5"
                autocomplete="off"
                :name="postalName"
                :readonly="disabled || hasPendingDemande"
                :disabled="disabled || hasPendingDemande"
                v-model="ppostal"
                v-mask="['#####']"
                title="Code postal valide."
                pattern="^(?!99)(?!00000)\d{5}$"
                @keyup="onPostalCodeChange"
            >
            <small
                class="form-text text-muted"
                v-if="hasPendingDemande">
                En cours de traitement
            </small>
            <div
                class="invalid-feedback"
                v-html="cpErrors.join('<br>')"
            ></div>
        </div>
        <div class="form-group col-md-6">
            <label for="city">{{ cityLabel }}</label>
            <div class="suggestion">
                <select
                    class="form-control custom-select"
                    :class="{'is-invalid': cityErrors.length > 0 }"
                    id="city"
                    v-model="pcity"
                    :name="cityName"
                    :readonly="disabled || hasPendingDemande"
                    :disabled="disabled || hasPendingDemande"
                    @focus="isFocused = true"
                    @blur="blurInput"
                >
                    <option v-for="(city, cidx) in cities" :key="cidx" :value="city.nom">
                        {{ city.nom }}
                    </option>
                </select>
                <small
                    class="form-text text-muted"
                    v-if="hasPendingDemande">
                    En cours de traitement
                </small>
                <div
                    class="invalid-feedback"
                    v-html="cityErrors.join('<br>')"
                ></div>
            </div>
        </div>
    </div>
</template>

<script>
import axios from 'axios';

export default {
    props: {
        postal: {type: String, default: ''},
        postalName: {type: String, default: null},
        city: {type: String, default: ''},
        cityName: {type: String, default: null},
        hasPendingDemande: {type: Boolean, default: false},
        cpErrors: {type: Array, default: () => []},
        cityErrors: {type: Array, default: () => []},
        disabled: {type: Boolean, default: false},
    },

    data() {
        return {
            ppostal: this.postal,
            pcity: this.city,
            cities: [],
            baseCities: [],
            isFocused: false,
            cityLabel: 'Ville/Commune',
            firstLoad: true
        };
    },

    mounted() {
        this.onInputPostalCode(0);
    },

    methods: {
        blurInput() {
            setTimeout(() => this.isFocused = false, 200);
        },

        getCitiesFromPostalCode(postal) {
            return axios.get('https://geo.api.gouv.fr/communes', {
                params: {
                    codePostal: postal,
                    boost: 'population',
                },
            }).then((res) => res.data);
        },

        resetCity() {
            this.pcity = ''; // Réinitialiser la ville sélectionnée
            this.cities = []; // Réinitialiser la liste des villes
        },

        onPostalCodeChange() {
            this.resetCity();  // Vider le champ ville
            this.onInputPostalCode(1); // Mettre à jour les villes en fonction du nouveau code postal
        },

        onInputPostalCode(autoedit) {
            if (this.ppostal.length >= 5) {
                this.getCitiesFromPostalCode(this.ppostal).then((data) => {

                    // Cas utilisateur étranger
                    if (data.length === 0) {
                        this.cities = [
                            {
                                nom: this.pcity
                            }
                        ];
                        this.cityLabel = 'Ville/Pays'
                        return;
                    }

                    // Tri par population
                    this.baseCities = data.sort((a, b) => {
                        if (a.population === b.population) return 0;
                        return a.population < b.population ? 1 : -1;
                    });
                    this.cities = this.baseCities;

                    // Si une seule ville remonte de l'API
                    if (this.cities.length === 1) {
                        this.pcity = this.cities[0].nom;
                        return;
                    }

                    // Normaliser pcity pour correspondre à la casse de city.nom
                    const selectedCity = this.cities.find(
                        city => city.nom.toLowerCase() === this.pcity.toLowerCase()
                    );

                    // Si une correspondance est trouvée, ajuster pcity à la valeur normalisée
                    if (selectedCity) {
                        this.pcity = selectedCity.nom; // Mettre pcity à la valeur correcte
                    } else {

                        // fix : le problème pour le problème de typo de ville exemple : ST PIERRE LES NEMOURS au lieu SAINT PIERRE LES NEMOURS
                        if (this.firstLoad) {
                            this.cities = [
                                {
                                    nom: this.pcity
                                }
                            ];
                            this.firstLoad = false;
                            return;
                        }

                        const tempCities = [];

                        this.cities = this.cities.map(city => {
                            tempCities.push({
                                nom: city.nom
                            })
                        })

                        this.cities = tempCities;
                    }
                });
            }
        },
    },
}
</script>

<style>
    #city {
        text-transform: uppercase;
    }
</style>
