<template>
    <div class="header-overlay-mobile"
         :class="{'header-overlay-mobile--is-connected':isConnected}"
    >
        <div class="header-overlay-mobile__container">

            <search-form :search-url="searchUrl" class="header-overlay-mobile__search-form" />

            <div class="sliding-panels" ref="scrollContainer">
                <transition name="level-1" mode="out-in">
                    <mobile-menu-link-list v-if="!selectedItem"
                                           :items="items"
                                           @item-click="selectedItem = $event"
                                           class="header-overlay-mobile__level-l sliding-panels__panel"
                    />
                </transition>
                <transition name="level-2" mode="out-in">
                    <mobile-menu-link-list v-if="secondLevelItems.length"
                                           :items="secondLevelItems"
                                           class="header-overlay-mobile__level-2 sliding-panels__panel"
                                           has-back-button
                                           @back="goBack"
                                           v-keyup-listener:ArrowLeft="goBack"
                    />
                </transition>
            </div>
        </div>
    </div>
</template>

<script>
import SearchForm from '@/app/components/SearchForm';
import MobileMenuLinkList from '@/app/components/MobileMenuLinkList';
import keyupListener from '@/app/directives/escape-listener.js';
import { enableBodyScroll, disableBodyScroll } from 'body-scroll-lock';

export default {
    name: "HeaderOverlayMobile",

    components: {
        SearchForm,
        MobileMenuLinkList,
    },

    directives: {
        keyupListener,
    },

    props: {
        items: { type: Array, default: () => [] },
        espacePersoUrl: { type: String, required: true },
        searchUrl: { type: String, required: true },
        isConnected: {type: Boolean, default: false},
    },

    data() {
        return {
            selectedItem: undefined,
        };
    },

    mounted() {
        disableBodyScroll(this.$refs.scrollContainer);
    },

    beforeDestroy() {
        enableBodyScroll(this.$refs.scrollContainer);
    },

    computed: {
        secondLevelItems() {
            if (!this.selectedItem || !(this.selectedItem.submenu instanceof Array) || !this.selectedItem.submenu) {
                return [];
            }

            return this.selectedItem.submenu;
        },
    },

    methods: {
        goBack() {
            this.selectedItem = undefined;
        },
    },
}
</script>

<style scoped lang="scss">
@import "~@/assets/styles/abstracts/_variables.scss";

.header-overlay-mobile {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;

    display: flex;
    flex-direction: column;
    align-items: center;

    padding: 0 1rem 1.25rem;

    background-color: $white;
    border-top: 1px solid $gray-200;

    &__container {
        padding-top: 60px;
        display: flex;
        flex-direction: column;

        width: 100%;
        max-width: 25rem;
        height: 100%;
    }

    &__search-form,
    &__my-account-button {
        margin: 1.5rem 0 0.5rem 0;
    }

    .sliding-panels {
        overflow-x: hidden;
        overflow-y: auto;
        position: relative;

        height: 100%;

        &__panel {
            position: absolute;
            top: 0;
            bottom: 0;
            left: 0;
            right: 0;
        }
    }

    .level-1-enter-active, .level-1-leave-active {
        transition: transform 200ms ease-out;
    }
    .level-1-enter, .level-1-leave-to {
        transform: translateX(-100%);
    }
    .level-2-enter-active, .level-2-leave-active {
        transition: transform 200ms ease-out;
    }
    .level-2-enter, .level-2-leave-to {
        transform: translateX(100%);
    }
}
</style>
